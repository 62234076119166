import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

/* ====================================================
    Adding class to mobile main menu
   ==================================================== */ 
$(document).ready(function($){
    
    // Get current url
    // Select an a element that has the matching href and apply a class of 'active'. Also prepend a - to the content of the link
    var url = window.location.href;
    
    var root = location.pathname;
    
    // Will also work for relative and absolute hrefs

    if ( root == '/') {

        $(".root-page").addClass("active");

    } else {

        $('.main-nav ul.menu a').each(function () {     
            if (this.href == window.location.href) {
                $(this).parents('li').find('> a').addClass("active");
            } 
        });

    }

    $('.topsmNav a').each(function () {     
        if (this.href == window.location.href) {
            $(this).parents('li').find('> a').addClass("active");
        } 
    });

    $('.agreement').click(function(){
        if($(this).prop("checked") == true){
   
            $('.download-coupon').prop("disabled", false);
             $('.download-coupon').on('click', function() {
                if($(this).hasClass('savings')){
                   window.open("https://sservices.trialcard.com/Coupon/ConcertaGR"); 
                }else{
                      window.open("https://sservices.trialcard.com/Coupon/Concerta");
                    }
                })
              
        }
        else if($(this).prop("checked") == false){

            $('.download-coupon').prop("disabled", true);
             $('.download-coupon').attr("href", "");
        }
    });

    /* ===========================
    close Corona Banner
    =========================== */

    $('#closeCorona').on('click', function() {

        $('.bannerContainer').slideUp();
        $('.sideisi-section').animate({marginTop: '-=30px'});
        
    })

    $('.social-share-area').on('click', function () {


        console.log('here');
        
        if($('.social-share-menu').hasClass('opened')){
            $('.social-share-menu').hide().removeClass('opened');
        } else {
            $('.social-share-menu').css('display', 'flex').addClass('opened');
        }

    })

    // $("*").each(function() {
    //     if ($(this).width() > document.body.clientWidth) {
    //         console.log(this);
    //     }
    // });
});

/* ====================================================
   ISI
   ==================================================== */ 

$(document).ready(function($){
    
    // if at home page, hide the ISI since there isn't enought content
     $('#sticky-isi').show();
    //  $('.sideisi-section').show();

    if(typeof ishome !== 'undefined' || typeof iscontact !== 'undefined'){

        if(typeof ishome !== 'undefined') {

           // $('.sideisi-section').addClass("home");
          //  $('footer').addClass('home-footer');
            $('.sideisi-section').show();
          //  $('#non-sticky-isi').detach().insertAfter('#isi-waypoint').hide();

        } else if (typeof iscontact !== 'undefined') {

            $('.sideisi-section').addClass("home");
            $('.sideisi-section').addClass("contact");
            $('footer').addClass('contact-footer');
         //   $('#non-sticky-isi').detach().insertAfter('#isi-waypoint').hide();
            // $('.sideisi-section').show();

        }
        
    }

  $('.menu-hb').on('click', function () {

    if($('.mobile-drop').hasClass('pulled')){

        $('.mobile-drop').slideUp().removeClass('pulled');

    } else {

        $('.mobile-drop').slideDown().addClass('pulled');

    }
      
  })

  $('#coupon-click').on('click', function () {
     // location.href = "{{root}}/coupon.html";
    //location.href = window.location.origin + '{{root}}/coupon.html'
      //coupon-click
  })

});

var isiwaypoint = new Waypoint({
  element: document.getElementById('isi-waypoint'),
  handler: function(direction) {
       if(direction == 'down') {
        //  if(!ishome){
               $('#sticky-isi').hide();
              // $('#non-sticky-isi').show();
         //    }
            
       }else if(direction == 'up'){
          //  if(!ishome){
              //  $('#non-sticky-isi').hide();
                $('#sticky-isi').show();
           //  }
           
       }
    console.log('Direction: ' + direction);
  },
  offset: '75%'
    
})



$(window).on("load",function(){

  setTimeout(function () {
    $(window).scrollTop(0); //scroll to top on refresh
  }); 
    
   $('.backtotop').on('click', function (e) {
       $(window).scrollTop(0);
   });   
    
 
});

//----- mobile ISI -----------------------

$(window).on("resize",function(){
    
  if($(this).width()>1023){
      // $('.sideisi-section').show();
       $('#sticky-isi').removeClass('mob_isi');
     //  $('#sticky-isi').hide();
       $(".s_header").unbind();
    

  }

  else if($(this).width() <= 1023) {  // add mobile style and button in ISI and IND
    //  $('.sideisi-section').hide();
      $('#sticky-isi').show();
      $('#sticky-isi').addClass('mob_isi');
         $('.s_header').on('click', function (e) {
                  //$('.mob_isi').toggleClass('open');
                  $(this).toggleClass('isiOn');
             
          if($(this).hasClass('ind') ){ // =========f mobile IND clicked 

                 //--if ind already open close it
                  if($('.sIND').hasClass('On')){ 
                      $('.mob_isi').removeClass('open');
                      $('.sIND').removeClass('On');
                  }else{
                      $('.mob_isi').addClass('open');
                      $('.sIND').addClass('On');
                      }
                
                  //---if ISI already on - close it 
                  if($('.sISI').hasClass('On')){ 
                      $('.sISI').removeClass('On');
                      $('.s_header.isi').removeClass('isiOn');
                      
                      
                  }else{ // ---if ISI not open keep it in place
                   
                  }
                    
              
            }else{ //============================= if mobile ISI clicked  
                
                //--if isi already open close it
                if($('.sISI').hasClass('On')){ 
                      $('.mob_isi').removeClass('open');
                      $('.sISI').removeClass('On');
                      $('.s_header.ind').removeClass('isiOn');
                  }else{
                      $('.mob_isi').addClass('open');
                      $('.sISI').addClass('On');
                     
                      }
                
                //---if IND already on close it 
                if($('.sIND').hasClass('On')){
                    $('.sIND').removeClass('On');
                      $('.s_header.ind').removeClass('isiOn');
                  }else{
                  
                  }
            }
        });
                                       
      console.log("small " + $(this).width());
  }

}).trigger("resize");


/* ====================================================
    Dropdown menu on click close
   ==================================================== */
$(function(){ 
     var navMain = $("#mobile-menu");

     navMain.on("click", "a.isi", null, function () {
         navMain.css('display','none');
     });
 });




/* ====================================================
    External Link Modal Pop Ups
   ==================================================== */

/* ===========================
    Third Party Links
   =========================== */

/*Patient External link*/
$('a.extlink').on("mousedown touchstart", function(e) {
    e.preventDefault(); // Prevent the href from redirecting directly
    var linkURLThirdparty = $(this).attr("href");
    warnBeforeRedirectThirdparty(linkURLThirdparty);
});

window.warnBeforeRedirectThirdparty = function warnBeforeRedirectThirdparty(linkURLThirdparty) {
    swal({
        title: "You are now leaving www.INTELENCE.com",
        html: "<p>By clicking “CONTINUE” below, you will be taken to a website that may contain links or references to other websites to which our <a href='https://www.intelence.com/privacy-policy.html'>Privacy Policy</a> may not apply. We encourage you to read the Privacy Policy of every website you visit. </p><p>You are solely responsible for your interactions with such websites.</p>",
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue', 
        allowOutsideClick: true,
        showCloseButton: true,
        showCancelButton: true,
        useRejections: true,
        animation: false

    }).then(okay => {
      // Redirect the user
      if (okay) {
        window.open(linkURLThirdparty);
      }
    });
};

/*HCP External link*/
$('a.extlinkHCP').on("mousedown touchstart", function(e) {
    e.preventDefault(); // Prevent the href from redirecting directly
    var linkURLThirdpartyHCP = $(this).attr("href");
    warnBeforeRedirectThirdpartyHCP(linkURLThirdpartyHCP);
});

window.warnBeforeRedirectThirdpartyHCP = function warnBeforeRedirectThirdpartyHCP(linkURLThirdpartyHCP) {
    swal({
        title: "You are now leaving Intelence.com/hcp.html",
        html: "<p>By clicking “Continue” below, you will be taken to a website that may contain links or references to other websites to which our <a href='https://www.intelence.com/hcp/privacy-policy.html'>Privacy Policy</a> may not apply. We encourage you to read the Privacy Policy of every website you visit. </p><p>You are solely responsible for your interactions with such websites.</p>",
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue', 
        allowOutsideClick: true,
        showCloseButton: true,
        showCancelButton: true,
        useRejections: true,
        animation: false

    }).then(okay => {
      // Redirect the user
      if (okay) {
        window.open(linkURLThirdpartyHCP);
      }
    });
};


/* ===========================
    Patient to HCP
   =========================== */
$('a.extHCP').on("mousedown touchstart", function(e) {
    e.preventDefault(); // Prevent the href from redirecting directly
    var linkURLHCP = $(this).attr("href");
    warnBeforeRedirectHCP(linkURLHCP);
});

window.warnBeforeRedirectHCP = function warnBeforeRedirectHCP(linkURLHCP) {
    swal({
        title: "Are You a Healthcare Professional?",
        html: "<p>You have selected the Healthcare Professional site entry. If you are a Healthcare Professional, click “CONTINUE” below.</p>",
        cancelButtonText: 'Cancel',
        customClass: 'HCP_popup',
        confirmButtonText: 'Continue', 
        allowOutsideClick: true,
        showCloseButton: true,
        showCancelButton: true,
        useRejections: true,
        animation: false

    }).then(okay => {
      // Redirect the user
      if (okay) {
        window.open(linkURLHCP, '_top');
      }
    });
};


/* ===========================
    Reveal Auto open
   =========================== */
$(document).ready(function(){
  $('#virologicModal').foundation('open')
});
